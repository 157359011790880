import React, { createContext, useContext, useState, useEffect } from 'react';

const RestaurantContext = createContext();

export const RestaurantProvider = ({ children }) => {
  const [isRestaurantClosedCtx, setIsRestaurantClosedCtx] = useState(true);

  return (
    <RestaurantContext.Provider value={{ isRestaurantClosedCtx, setIsRestaurantClosedCtx }}>
      {children}
    </RestaurantContext.Provider>
  );
};

export const useRestaurantStatus = () => {
  return useContext(RestaurantContext);
};

import React, { useEffect, useState } from "react";
import { Box, Typography, SwipeableDrawer } from "@mui/material";

import MenuListAddSubMenuBtn from "./MenuListAddSubMenuBtn";
import {
  getModifierTotalPrice,
  onModifierSelect,
  onSetDefaultModifierData,
} from "../constants/commonFunc";
import AddItemToCartInterface from "./AddItemToCartInterface";
import { formatCurrency } from "../utils/utils";

const AddMenuToCartModal = (props) => {
  const [cartCount, setCartCount] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [addedOptions, setAddedOptions] = useState([]);
  const [addedModifiers, setAddedModifiers] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  let { data } = props;

  useEffect(() => {
    setModalVisible(props.visible);
    setCartCount(1);
  }, [props.visible]);

  useEffect(() => {
    const modifier = onSetDefaultModifierData(
      props.data?.modifiers,
      props?.menuList,
      "modal"
    );
    setAddedModifiers(modifier);
  }, [props.data]);

  useEffect(() => {
    const menuItemTotalPrice =
      ((data?.price?.value ?? 0) +
        getModifierTotalPrice(
          data?.modifiers?.filter((x) => !x.addUpPrices)?.map((x) => x?._id) ??
            [],
          addedModifiers
        )) *
      cartCount;
    setTotalPrice(menuItemTotalPrice);
  }, [addedModifiers, cartCount]);

  const addSubMenu = (type) => {
    let count = cartCount;
    if (type === "-" && cartCount > 1) {
      count = cartCount - 1;
      addedOptions.pop();
    }
    if (type === "+") {
      count = cartCount + 1;
    }
    setCartCount(count);
  };

  const onCloseModal = () => {
    props.onClose();
  };

  const onModifierClick = (items, menu_data) => {
    const data = [...addedModifiers];
    const value = onModifierSelect(data, items, menu_data);
    setAddedModifiers(value);
  };

  const onAddMenuItemToCart = () => {
    props.onAddMenuItemToCart(
      props.data,
      "",
      addedOptions,
      addedModifiers,
      cartCount
    );
  };

	return (
		<>
			<SwipeableDrawer
				sx={{
					"& .MuiDrawer-root": {
						zIndex: 100,
					},
					"& .MuiPaper-root": {
						overflow: "hidden",
						borderTopLeftRadius: "18px",
						borderTopRightRadius: "18px",
						height: "80vh",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#EBEBEB",
					},
				}}
				anchor='bottom'
				open={modalVisible}
				onClose={onCloseModal}
				onOpen={() => {}}
				disableSwipeToOpen
				transitionDuration={{
					enter: 300,
					exit: 350,
				}}
			>
				<Box sx={{ flex: 1, overflow: "hidden" }}>
					<AddItemToCartInterface
						showAddToCart={props.addToCartExist}
						onAddMenuItemToCart={onAddMenuItemToCart}
						onModifierClick={onModifierClick}
						addSubMenu={addSubMenu}
						itemData={data}
						menuList={props.menuList}
						addedModifiers={addedModifiers}
						onClose={onCloseModal}
					/>
				</Box>
			</SwipeableDrawer>

      {props?.addToCartExist && modalVisible ? (
        <Box
          sx={{
            height: 58,
            position: "fixed",
            left: 0,
            right: 0,
            bottom: "20px",
            padding: "0 16px",
            zIndex: 50000,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuListAddSubMenuBtn
              theme={"theme2"}
              count={cartCount ? cartCount : 1}
              inputWidth={"100%"}
              inputHeight={58}
              addSubWidth={50}
              onAddSubMenu={(type) => addSubMenu(type)}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: "#000000",
              borderRadius: "10px",
              height: "100%",
              width: "49%",
              color: "#ffffff",
            }}
            onClick={onAddMenuItemToCart}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", textAlign: "center" }}
            >
              Add for {formatCurrency(totalPrice)}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default AddMenuToCartModal;

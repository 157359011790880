import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import ARWebAddress from "./ARWebAddress";

import "./../assets/css/custom.css";

import { THEME_COLOR as THEMECOLOR } from "../containers/MenuList2";
import { THEME_COLOR } from "../constants";
import i18next from "../constants/i18n";

import { ReactComponent as DeliveryFastIcon } from "../assets/images/delivery_fast.svg";
import { ReactComponent as RightArrowIcon } from "../assets/images/right_arrow.svg";

let fontStyle1 = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold !important",
  },
};

const ARWebCheckout = (props) => {
  let { address, restTemplate } = props;

  return (
    <Box sx={styles.dataView} mx={2} mt={2}>
      <Box sx={styles.subMainView}>
        <Box sx={styles.addEditView}>
          <Typography
            sx={{ ...styles.subTitleText, ...fontStyle1[restTemplate] }}
          >
            {i18next.t("Shipping")}
          </Typography>
          {address == null ? null : (
            <Typography
              sx={{
                ...styles.addEditText,
                color: restTemplate == "temp1" ? "#C1A700" : THEMECOLOR,
              }}
              onClick={props.listAddress}
            >
              {i18next.t("Add/Edit")}
            </Typography>
          )}
        </Box>
        {address == null ? (
          <Box mt={2} sx={styles.addAddressMainView} onClick={props.addAddress}>
            <Box sx={styles.addAddressSubView}>
              <DeliveryFastIcon
                width={20}
                height={20}
                style={{
                  transform: props.locale == "ar" ? "scaleX(-1)" : "unset",
                }}
              />
              <Typography
                sx={styles.addAddressText}
                ml={props.locale == "ar" ? "unset" : 0.6}
                mr={props.locale == "ar" ? 0.6 : "unset"}
              >
                {i18next.t("Add Address")}
              </Typography>
            </Box>
            <RightArrowIcon
              width={20}
              height={20}
              style={{
                transform: props.locale == "ar" ? "scaleX(-1)" : "unset",
              }}
            />
          </Box>
        ) : (
          <Box>
            <ARWebAddress
              page="checkout"
              locale={props.locale}
              address={address}
            />
            <Box
              mt={1}
              sx={styles.rememberCardView}
              onClick={() =>
                props.setBillingSameAsDelivery(!address.billingSameAsDelivery)
              }
            >
              <Checkbox
                checked={address.billingSameAsDelivery}
                onChange={() =>
                  props.setBillingSameAsDelivery(!address.billingSameAsDelivery)
                }
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                  color: "#3498DB",
                  paddingLeft: props.locale == "ar" ? 1 : 0,
                  paddingRight: props.locale == "ar" ? 0 : 1,
                  "&.Mui-checked": {
                    color: restTemplate == "temp1" ? THEME_COLOR : THEMECOLOR,
                  },
                }}
              />
              <Typography sx={styles.rememberCardText}>
                {i18next.t("Billing and delivery addresses are same.")}&lrm;
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ARWebCheckout;

const styles = {
  dataView: {},
  subMainView: {
    borderRadius: "15px",
    backgroundColor: "#FFF",
    p: 2,
  },
  subTitleText: {
    color: "#212529",
    fontSize: 18,
    fontFamily: "GilroyBold",
  },
  addEditView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addEditText: {
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    cursor: "pointer",
  },
  addAddressMainView: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    height: 60,
    px: 2,
  },
  addAddressSubView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  addAddressText: {
    color: "#212529",
    fontSize: 15,
    fontFamily: "GilroyBold",
  },
  rememberCardView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rememberCardText: {
    color: "#212529",
    fontSize: 13,
    fontFamily: "GilroySemiBold",
    cursor: "pointer",
  },
};

import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Divider,
} from "@mui/material";
import { useRestaurantStatus } from "../../context/RestaurantContext";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RestaurantOpenHoursModal = ({ children }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDismissed, setIsModalDismissed] = useState(false);
	const location = useLocation();

	const { isRestaurantClosedCtx } = useRestaurantStatus();

	const restaurantName = useSelector(
		(state) => state.restaurantData?.restaurantDetail?.name
	);

	const openHoursData = useSelector(
		(state) => state.restaurantData?.restaurantDetail?.openHours
	);

	const openHours = openHoursData
		? Object.entries(openHoursData)
				.filter(([key]) => key !== "_id")
				.map(([dayName, { isOpen, timings }]) => ({
					dayName,
					isOpen,
					timings,
				}))
		: [];

  useEffect(() => {
		setIsModalDismissed(false);
	}, [location.pathname]);

	useEffect(() => {
		if (isRestaurantClosedCtx && !isModalDismissed) {
			setIsModalOpen(true);
		}
	}, [isRestaurantClosedCtx, isModalDismissed]);

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setIsModalDismissed(true);
	};

	return (
		<>
			<Dialog
				open={isModalOpen}
				onClose={handleCloseModal}
				fullWidth
				maxWidth='80vw'
				PaperProps={{
					sx: {
						borderRadius: "10px !important",
					},
				}}
			>
				<DialogTitle
					sx={{
						fontFamily: "Inter",
						fontWeight: 600,
						fontSize: "18px",
						p: "36px",
					}}
				>
					Welcome to {restaurantName}
				</DialogTitle>

				<Divider
					sx={{
						color: "#BD9968",
						mx: "36px",
					}}
				/>

				<DialogContent
					sx={{
						px: "36px",
						pb: 0,
					}}
				>
					<Typography
						variant='h6'
						sx={{
							fontFamily: "Inter",
							fontSize: "16px",
							color: "#011C3A",
						}}
					>
						Opening Time
					</Typography>

					{openHours.length > 0 && (
						<List>
							{openHours.filter(x => x?.timings?.length).map((day, index) => (
								<ListItem
									key={`${day?.dayName}-${index}`}
									disableGutters
									disablePadding
									sx={{
										mb: 1,
									}}
								>
									<ListItemText
										sx={{
											fontFamily: "Inter",
											fontWeight: 400,
											fontSize: "16px",
											textTransform: "capitalize",
										}}
									>
										{day?.dayName}
									</ListItemText>
									<ListItemSecondaryAction
										sx={{
											fontFamily: "Inter",
											fontWeight: 600,
											fontSize: "16px",
										}}
									>
										{day?.timings[0].from} - {day?.timings[0].to}
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					)}
				</DialogContent>

				<DialogActions
					sx={{
						px: "36px",
						pb: "36px",
					}}
				>
					<Button
						onClick={handleCloseModal}
						sx={{
							fontFamily: "Inter",
							fontWeight: 600,
							fontSize: "16px",
							background: "#BD9968 !important",
							color: "#ffffff !important",
							height: "40px",
							width: "89px",
							borderRadius: 0,
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default RestaurantOpenHoursModal;

import React from 'react';
import ReactDOM from 'react-dom/client';

import {Provider} from 'react-redux';
import {store, persistor} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { PaymentProvider } from './modules/eazy-peasy-checkout/context/PaymentContext';
import { OrdersProvider } from './modules/past-orders/context/OrdersContext';
import { RestaurantProvider } from './context/RestaurantContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RestaurantProvider>
          <PaymentProvider>
            <OrdersProvider>
              <App/>
            </OrdersProvider>
          </PaymentProvider>
        </RestaurantProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "../components/ARWebCheckoutCartItem.module.css";

import { ReactComponent as UpIcon } from "../assets/images/up_arrow_grey.svg";
import { ReactComponent as DownIcon } from "../assets/images/down_arrow_grey.svg";

import i18next from "../constants/i18n";
import { CURRENCY_SYMBOL, TABLE } from "../constants";
import { THEME_COLOR } from "../containers/MenuList2";
import { getOrdersWithTotalPrice } from "../constants/commonFunc";
import { formatCurrency } from "../utils/utils";

let fontFamilyStyle = {
	temp1: {},
	temp2: {
		fontFamily: "GilroySemiBold !important",
	},
};

let fontFamilyStyle2 = {
	temp1: {},
	temp2: {
		fontFamily: "GilroyBold !important",
	},
};

const ARWebCheckoutCartItem = (props) => {
	const [expand, setExpand] = useState(true);

	let { cartItems, page, restTemplate, menuList, tableNumber, orderType } =
		props;

	const cartList = useMemo(() => {
		return getOrdersWithTotalPrice(cartItems, menuList)?.cartItems ?? [];
	}, [cartItems, menuList]);

	return (
		<Box
			className={styles.mainView}
			style={
				page == "checkout" && restTemplate == "temp1"
					? {
							boxShadow: "0px 40px 70px -21px #72640E24",
							border: "1px solid #ffe227",
							borderRadius: "17px",
							backgroundColor: "transparent",
					  }
					: {
							border: "0px",
							borderRadius: "15px",
							backgroundColor: "#FFF",
							boxShadow: '0px 1px 3px 0px #00000040'
					  }
			}
			mx={1}
			mt={page == "checkout" ? 8 : 2}
			mb={page == "checkout" ? 2 : 0.5}
			px={2}
			py={2}
		>
			{page == "checkout" ? (
				<>
					<Box
						className={styles.blurImg}
						sx={restTemplate == "temp1" ? null : { backgroundColor: "#FFF" }}
					/>
					{restTemplate == "temp1" ? (
						<Box
							onClick={() => setExpand(!expand)}
							className={styles.expandIcon}
							sx={{
								cursor: { xl: "pointer" },
								path: {
									stroke: "#000",
								},
							}}
						>
							{expand ? (
								<UpIcon width={20} height={20} />
							) : (
								<DownIcon width={20} height={20} />
							)}
						</Box>
					) : null}
				</>
			) : null}
			<Box className={styles.orderTitleMainView}>
				<Box className={styles.orderTitleView}>
					<Typography
						className={styles.orderTitleText}
						sx={{ ...fontFamilyStyle[restTemplate] }}
					>
						{i18next.t("Your Order")}
					</Typography>
					{orderType === TABLE && tableNumber ? (
						<Box
							sx={{
								background: "#BD9968",
								height: "65px",
								width: "65px",
								borderRadius: "50%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								mr: 2,
								mt: 1,
							}}
						>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "20px !important",
									color: "#fff !important",
									fontWeight: 600,
								}}
							>
								{tableNumber}
							</Typography>
						</Box>
					) : null}
					{props.orderId ? (
						<Typography className={styles.orderNoText}>
							{i18next.t("Order no")} : {props.orderId.toString().substr(-5)}
						</Typography>
					) : null}
				</Box>
			</Box>
			{expand ? (
				<Box className={styles.cartItemListView} mt={2} mb={1}>
					{cartList.map((item, i) => {
						return (
							<Box sx={{ mb: 1 }}>
								<Box key={i} className={styles.cartItemMainView}>
									<Box dir={"ltr"} display={"flex"} flexDirection={"row"}>
										<Box className={styles.itemQtyView}>
											<Typography className={styles.itemQtyText}>
												{item.count}x
											</Typography>
										</Box>
										<Box className={styles.itemNameView} pr={2}>
											<Typography
												className={styles.itemNameText}
												sx={{ ...fontFamilyStyle2[restTemplate] }}
											>
												{item.name}
											</Typography>
										</Box>
									</Box>
									<Box className={styles.itemPriceView}>
										<Typography
											className={styles.itemPriceText}
											sx={{ ...fontFamilyStyle2[restTemplate] }}
										>
											{CURRENCY_SYMBOL?.[item?.price?.currency]}
											{item?.totalPrice?.toFixed(2)}
										</Typography>
									</Box>
								</Box>
								{item?.selectedModifiers?.length > 0 &&
									item?.selectedModifiers?.map((modifier, modIndex) => (
										<Box key={modIndex} className={styles.modifierItemView}>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												<Box className={styles.itemQtyView}>
													<Typography
														className={styles.itemQtyText}
														sx={{
															fontSize: "12px !important",
															fontFamily: "Inter !important",
															fontWeight: 300,
														}}
													>
														{item.count}x
													</Typography>
												</Box>
												<Box
													sx={{
														flex: 1,
													}}
													pr={2}
												>
													<Typography
														className={styles.modifierNameText}
														sx={{
															...fontFamilyStyle2[restTemplate],
															fontSize: "12px !important",
															fontFamily: "Inter !important",
															fontWeight: 300,
														}}
													>
														{modifier.name}
													</Typography>
												</Box>
												<Box className={styles.modifierPriceView}>
													<Typography
														className={styles.modifierPriceText}
														sx={{
															...fontFamilyStyle2[restTemplate],
															fontSize: "12px !important",
															fontFamily: "Inter !important",
															fontWeight: 300,
														}}
													>
														{modifier?.price?.value === 0 ? 'FREE' : formatCurrency(modifier?.price?.value * item?.count)}
													</Typography>
												</Box>
											</Box>
										</Box>
									))}
							</Box>
						);
					})}
				</Box>
			) : null}
		</Box>
	);
};

export default ARWebCheckoutCartItem;

import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import CheckoutHeader from "../shared/CheckoutHeader";
import LoadingScreen from "../shared/LoadingScreen";
import CheckoutFooter from "../shared/CheckoutFooter";
import PaymentDetails from "../PaymentDetails";

const PaymentLandingPage = () => {
	const [isFinalLoading, setFinalLoading] = useState(true);

	return (
		<Container
			sx={{
				px: 0,
				backgroundColor: "#f9f9f9",
				height: "100vh",
				maxHeight: "100vh",
				overflow: "auto",
				pb: 10,
			}}
		>
			<Box sx={{
				position: 'sticky',
				top: 0
			}}>
				<CheckoutHeader />
			</Box>

			{isFinalLoading && (
				<Box sx={{ position: "relative", top: "-8%" }}>
					<LoadingScreen />
				</Box>
			)}

			<Box
				sx={{
					visibility: !isFinalLoading ? "visible" : "hidden",
					opacity: !isFinalLoading ? 1 : 0,
					transition: "opacity 1s ease-in, visibility 0s linear 1s",
				}}
			>
				<PaymentDetails
					setFinalLoading={setFinalLoading}
					isFinalLoading={isFinalLoading}
				/>

				<CheckoutFooter />
			</Box>
		</Container>
	);
};

export default PaymentLandingPage;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { connect } from "react-redux";
import {
  updateRestaurantDetail,
  updateSelectedMenuData,
} from "../redux/actions/restaurantDataActions";
import {
  updateCategoryList,
  updateMenuList,
  updateModelList,
  updateRestData,
  updateTableData,
} from "../redux/actions/apiDataActions";

import * as Api from "../api/app";

import Loader from "../components/Loader";
import MenuList1 from "./MenuList1";
import MenuList2 from "./MenuList2";

import { APP_TYPE_BOTH, APP_TYPE_GOPOSH, TABLE, TEMP3REST } from "../constants";
import { checkRestIsClosed } from "../constants/commonFunc";
import { useRestaurantStatus } from "../context/RestaurantContext";

const catPageSize = 30000;
const menuPageSize = 30000;

const RestaurantMenu = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const { isRestaurantClosedCtx, setIsRestaurantClosedCtx } = useRestaurantStatus();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    urlParams?.id ? urlParams?.id : ""
  );
  const [restaurantData, setRestaurantData] = useState(null);
  const [addToCartExist, setAddToCartExist] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [categoryMenuList, setCategoryMenuList] = useState([]);
  const [orderType, setOrderType] = useState(
    urlParams?.table_id ||
      location.pathname.split("/").pop().startsWith("table")
      ? TABLE
      : ""
  );
  const [tableNumber, setTableNumber] = useState(
    urlParams?.table_id ? urlParams?.table_id : ""
  );

  useEffect(() => {
    let addCartExist =
      restaurantData?.appType === APP_TYPE_GOPOSH ||
      restaurantData?.appType === APP_TYPE_BOTH
        ? (orderType === TABLE && restaurantData?.tableOrder && tableNumber) ||
          (orderType !== TABLE && restaurantData?.onlineOrder)
        : false;

    const isClosed =
      restaurantData && checkRestIsClosed(restaurantData?.openHours);

    addCartExist = isClosed ? false : addCartExist;

    setAddToCartExist(addCartExist);
    setIsRestaurantClosedCtx(isClosed);
  }, [restaurantId, restaurantData]);

  useEffect(() => {
    if (
      props.restData?._id == restaurantId ||
      (typeof props.restData?.slug != "undefined" &&
        props.restData?.slug == urlParams?.slug)
    ) {
      setRestaurantData(props.restData);
      setRestaurantId(props.restData?._id);
      if (props.restaurantDetail?._id == props.restData?._id) {
        props.updateRestaurantDetail(props.restData);
      }
      getCategoryList(props.restData?._id, false);
    }
    if (location?.state?.previousPage != "ar_page") {
      getRestaurant(true);
    }
  }, []);

  useEffect(() => {
    if (orderType != TABLE) {
      props.updateTableData(null);
    }
  }, [orderType]);

  const getRestaurant = (api_call) => {
    let endpoint = restaurantId;
    if (urlParams?.slug) {
      endpoint = "byname/" + urlParams?.slug;
    }
    Api.getRestaurant(endpoint).then((response) => {
      if (response.success) {
        setRestaurantId(response.data?._id);
        setRestaurantData(response.data);
        props.updateRestData(response.data);
        if (props.restaurantDetail?._id == response.data?._id) {
          props.updateRestaurantDetail(response.data);
        }
        getCategoryList(response.data?._id, api_call);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getCategoryList = async (rest_id, api_call) => {
    if (!api_call) {
      const list = props.categoryList.filter(
        (x) => x?._idRestaurant === rest_id
      );
      if (list.length) {
        getMenuList(rest_id, list, api_call);
      }
    } else {
      const filter = `?pageSize=${catPageSize}&pageNum=1&filter_enabled=true`;
      const response = await Api.getCategoryList(rest_id, filter);
      const data =
        response?.data?.rows?.sort((a, b) => a?.order - b?.order) ?? [];
      if (response?.success) {
        props.updateCategoryList(data);
        data?.length
          ? getMenuList(rest_id, data, api_call)
          : setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const getMenuList = (rest_id, category, api_call) => {
    if (!api_call) {
      const list = props.menuList.filter((x) => x?._idRestaurant === rest_id);
      if (list?.length) {
        getMenuListResponse(list, category);
      }
    } else {
      const filter = `?pageSize=${menuPageSize}&pageNum=1&orderBy=order&orderByDir=asc&filter__idRestaurant=${rest_id}&filter_enabled=true`;

      Api.getMenuList(filter).then((response) => {
        if (response.success) {
          let data = response.data.rows;
          props.updateMenuList(data);
          getMenuListResponse(data, category);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const getMenuListResponse = (data, category) => {
    let list = [];
    category.forEach((x) => {
      let cat_menu = {
        ...x,
        menu: data.filter((y) => y._idCategory == x._id),
      };
      list.push(cat_menu);
    });
    setCategoryList(category);
    setMenuList(data);
    setCategoryMenuList(list);
    setIsLoading(false);
  };

  const onBackBtnClick = (params = "") => {
    let url = `/restaurant/${restaurantId}`;
    if (location.pathname.split("/")[1] == "r") {
      url = `/r/${urlParams?.slug}`;
    }
    if (orderType == TABLE) {
      if (tableNumber) {
        url = `${url}/table/${tableNumber}`;
      } else {
        url = `${url}/table`;
      }
    }
    navigate(`${url}${params}`);
  };

  const onMenuArViewClick = (data) => {
    if (data.modelId != null && data.modelId != "") {
      props.updateSelectedMenuData(data);
      let params = "?menu_id=" + data._id;
      onBackBtnClick(params);
    }
  };

  const onCartClick = () => {
    let rest_id = restaurantId;
    if (props.restaurantDetail?._id) {
      rest_id = props.restaurantDetail?._id;
    }

    let url = `/cart/${rest_id}`;
    if (location.pathname.split("/")[1] == "r") {
      url = `/r/cart/${urlParams?.slug}`;
    }
    if (orderType == TABLE) {
      if (tableNumber) {
        url = `${url}/table/${tableNumber}`;
      } else {
        url = `${url}/table`;
      }
    }
    navigate(`${url}`);
  };

  if (isLoading) {
    return <Loader height={{ height: window.innerHeight }} />;
  }

  if (!TEMP3REST.includes(restaurantId)) {
    return (
      <MenuList2
        orderType={orderType}
        restaurantId={restaurantId}
        restaurantData={restaurantData}
        addToCartExist={addToCartExist}
        menuList={menuList}
        categoryList={categoryList}
        onMenuArViewClick={onMenuArViewClick}
        onBackBtnClick={onBackBtnClick}
        onCartClick={onCartClick}
      />
    );
  } else {
    return (
      <MenuList1
        tableNumber={tableNumber}
        orderType={orderType}
        restaurantId={restaurantId}
        restaurantData={restaurantData}
        addToCartExist={addToCartExist}
        menuList={menuList}
        categoryMenuList={categoryMenuList}
        onMenuArViewClick={onMenuArViewClick}
        onBackBtnClick={onBackBtnClick}
        onCartClick={onCartClick}
      />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.restaurantData.cartItems,
    restaurantDetail: state.restaurantData.restaurantDetail,
    locale: state.restaurantData.locale,
    restData: state.apiData.restData,
    categoryList: state.apiData.categoryList,
    menuList: state.apiData.menuList,
    modelList: state.apiData.modelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updateSelectedMenuData: (data) => dispatch(updateSelectedMenuData(data)),
    updateRestData: (data) => dispatch(updateRestData(data)),
    updateCategoryList: (data) => dispatch(updateCategoryList(data)),
    updateMenuList: (data) => dispatch(updateMenuList(data)),
    updateModelList: (data) => dispatch(updateModelList(data)),
    updateTableData: (data) => dispatch(updateTableData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantMenu);

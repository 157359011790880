import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/utils";

const YourOrderWidget = ({ orderData, cartItems }) => {
	console.log("cartItems => ", cartItems);

	const orderNumber = orderData?._id.toString().substr(-5);

	return (
		<Box
			sx={{
				mx: 1,
				mt: 3,
				p: 2,
				boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
				borderRadius: "15px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					mb: 1,
				}}
			>
				<Typography
					sx={{
						fontSize: "22px",
						fontFamily: "Inter",
						fontWeight: 600,
            display: 'flex',
            alignItems: "center",
					}}
				>
					Your Order
				</Typography>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						fontWeight: 600,
						color: "#000000",
					}}
				>
					Order no: {orderNumber}
				</Typography>
			</Box>

			<List>
				{cartItems?.map((item, index) => (
					<ListItem
						key={index}
						sx={{
							p: 0,
							display: "flex",
							flexDirection: "column",
							alignItems: "stretch",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								flex: 1,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									gap: 1,
                  alignItems: "center",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Inter",
										fontSize: "15px",
										fontWeight: 400,
										color: "#6B6B6B",
									}}
								>
									{item?.count}x
								</Typography>

								<Typography
									sx={{
										fontFamily: "Inter",
										fontSize: "15px",
										fontWeight: 600,
										color: "#000000",
										textTransform: "capitalize",
									}}
								>
									{item?.name}
								</Typography>
							</Box>

							<Box
								sx={{
									fontFamily: "Inter",
									fontSize: "16px",
									fontWeight: 600,
									color: "#000000",
									flex: 1,
									textAlign: "right",
								}}
							>
								{formatCurrency(item?.price?.value)}
							</Box>
						</Box>

						{item?.selectedModifiers?.map((modifier, modIndex) => (
							<Box
								key={modIndex}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									flex: 1,
								}}
							>
								<Box
									sx={{
										flex: 1,
										display: "flex",
										gap: 1,
                    alignItems: 'center'
									}}
								>
                  <Typography
									sx={{
										fontFamily: "Inter",
										fontSize: "15px",
										fontWeight: 400,
										color: "#6B6B6B",
									}}
								>
									{item?.count}x
								</Typography>
									<Typography sx={{
										fontFamily: "Inter",
										fontSize: "13px",
										fontWeight: 600,
										color: "#000000",
										textTransform: "capitalize",
									}}>{modifier?.name}</Typography>
								</Box>
								<Typography
									sx={{
										fontFamily: "Inter",
										fontSize: "14px",
										fontWeight: 600,
										color: "#000000",
										flex: 1,
										textAlign: "right",
									}}
								>
									{formatCurrency(modifier?.price?.value.toString())}
								</Typography>
							</Box>
						))}
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default YourOrderWidget;

import React, { useEffect, useState, useRef, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ButtonBase from "@mui/material/ButtonBase";

import { connect } from "react-redux";
import {
  updateCartItems,
  updateRestaurantDetail,
  updateSelectedMenuData,
} from "../redux/actions/restaurantDataActions";

import MenuTypeFilter from "../components/MenuTypeFilter";
import MenuSearchInput from "../components/MenuSearchInput";
import MenuListHeader, {
  MENU_HEADER_HEIGHT,
} from "../components/MenuListHeader";
import AddMenuToCartModal from "../components/AddMenuToCartModal";
import MenuListAddSubMenuBtn from "../components/MenuListAddSubMenuBtn";

import { ReactComponent as UpArrowIcon } from "../assets/images/up_arrow_black.svg";
import { ReactComponent as DealIcon } from "../assets/images/deal.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import { ReactComponent as MenuImg } from "../assets/images/file1.svg";
import { ReactComponent as RightArrow } from "../assets/images/right_arrow.svg";
import { ReactComponent as RightLightArrow } from "../assets/images/right_light_arrow.svg";
import { ReactComponent as LeftDarkArrow } from "../assets/images/left_dark_arrow.svg";
import { ReactComponent as LeftArrow } from "../assets/images/left_arrow.svg";
import { ReactComponent as ARMenuImg } from "../assets/images/ar_view.svg";

import styles from "./MenuList2.module.css";

import { CURRENCY_SYMBOL, MENU_TYPE2 } from "../constants";
import {
  addMenuItemToCart,
  checkModifierAddedToCart,
  filterMenuType,
  getRequiredModifierPrice,
} from "../constants/commonFunc";

import RestaurantOpenHoursModal from '../modules/restaurant-closed/RestaurantOpenHoursModal';
import { useRestaurantStatus } from '../context/RestaurantContext';

export let THEME_COLOR = "#b99c74";

export let THEME_COLOR2 = "#1d3153";

let FILTER_LIST = [
  {
    _id: 1,
    name: "Chef' Recommends",
  },
  {
    _id: 2,
    name: "New Arrivals",
  },
  {
    _id: 3,
    name: "Appetizers",
  },
  {
    _id: 4,
    name: "Soups & Salads",
  },
  {
    _id: 5,
    name: "Mains",
  },
  {
    _id: 6,
    name: "BAOS",
  },
  {
    _id: 7,
    name: "Wings",
  },
  {
    _id: 8,
    name: "Pizza",
  },
  {
    _id: 9,
    name: "Burgers",
  },
];

let bgColor = { true: THEME_COLOR2, false: THEME_COLOR };
let textColor = { true: "#FFF", false: "#222" };
let textColor2 = { true: THEME_COLOR2, false: "#555" };

let PADDING_HORIZONTAL = 16;
const BOTTOM_HEIGHT = 60;
let TOP_SEARCH_VIEW_HEIGHT = 50;
let CATEGORY_HEIGHT = 50;

let TOP_VIEW_HEIGHT = MENU_HEADER_HEIGHT + TOP_SEARCH_VIEW_HEIGHT;

const filterCat = (cat_list, menu_list) => {
  return JSON.parse(JSON.stringify(cat_list)).filter(
    (x) =>
      !x?.name?.toLowerCase()?.includes("modifier") &&
      x?.enabled &&
      menu_list.filter((y) => y?._idCategory === x?._id && y?.enabled).length
  );
};

const filterMenuByCat = (menuList, cat, search, menuType) => {
  const categoryId = cat?._id;
  const searchLower = search.toLowerCase();

  return menuList.filter(
    (x) =>
      x._idCategory === categoryId &&
      x?.enabled &&
      x?.name?.toLowerCase().includes(searchLower) &&
      (x.extras?.menuType === menuType || menuType === "all")
  );
};

const MenuList2 = (props) => {
  let { restaurantId, addToCartExist } = props;
  const { isRestaurantClosedCtx } = useRestaurantStatus();

  const [categoryList, setCategoryList] = useState(
    filterCat(props.categoryList, props.menuList)
  );
  const [menuList, setMenuList] = useState(props.menuList);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [menuType, setMenuType] = useState("all");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [addMenuToCartModalVisible, setAddMenuToCartModalVisible] =
    useState(false);
  const [menuModelData, setMenuModelData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [showNoItemText, setShowNoItemText] = useState(false);
  const [menuTypeFilterList, setMenuTypeFilterList] = useState([]);

  const menuScrollRef = useRef(null);

  useEffect(() => {
    setSelectedFilter(FILTER_LIST[0]);
  }, []);

  useEffect(() => {
    let category_list = [];
    filterCat(props.categoryList, menuList).map((item) => {
      let menu_list = filterMenuByCat(menuList, item, searchText, menuType);
      if (menu_list.length == 0) {
      } else {
        category_list.push(item);
      }
    });
    if (category_list.length == 0) {
      category_list = filterCat(props.categoryList, menuList);
      setShowNoItemText(true);
    } else {
      setShowNoItemText(false);
    }
    setCategoryList(category_list);

    setSelectedCategory(category_list.length ? category_list[0] : null);
  }, [searchText, menuType, menuList]);

  useEffect(() => {
    let cart_items = props.cartItems.filter(
      (x) => x._idRestaurant == restaurantId
    );
    setCartItems(cart_items);
  }, [props.cartItems, restaurantId]);

  useEffect(() => {
    if (categoryList.length) {
      let category_id = categoryList[0]._id;
      let list = null;
      categoryList.map(
        (x, i) =>
          (list = {
            ...list,
            [x?._id]: document.getElementById("menuListCategory" + x?._id)
              ? {
                  current: document.getElementById("menuListCategory" + x?._id),
                }
              : React.createRef(),
          })
      );
      menuScrollRef.current = list;
      if (category_id) {
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(
            0,
            menuScrollRef.current?.[category_id]?.current?.offsetTop
          );
        }
      }
    }
  }, [categoryList]);

  useEffect(() => {
    const list = props.menuList.filter(
      (x) => typeof x.extras?.menuType !== "undefined"
    );
    const menuTypeList = filterMenuType(list);
    setMenuTypeFilterList(menuTypeList);
    setMenuList(props.menuList);
  }, [props.menuList]);

  const onAddMenuItemToCart = (item, note, options, modifiers, count) => {
    const list = addMenuItemToCart(
      restaurantId,
      props.restaurantDetail?._id,
      cartItems,
      count,
      item,
      modifiers,
      options,
      note
    );

    if (restaurantId != props.restaurantDetail?._id) {
      props.updateRestaurantDetail(props?.restaurantData);
    }
    props.updateCartItems(list);

    if (count > 0) {
      setAddMenuToCartModalVisible(false);
      setMenuModelData(null);
    }
  };

  const handleMainScroll = (e) => {
    if (e.target.scrollTop) {
      let filter_cat = null;
      Object.keys(menuScrollRef.current).forEach(function (key, index) {
        let x = menuScrollRef.current[key];
        if (
          x.current?.offsetTop + x.current?.clientHeight >=
          e.target.scrollTop
        ) {
          if (filter_cat == null) {
            filter_cat = x;
          }
        }
      });
      if (filter_cat != null) {
        let cat_id = filter_cat?.current?.attributes["cat-id"]["value"];
        if (selectedCategory?._id != cat_id) {
          categoryList.map((x, i) => {
            if (x._id == cat_id) {
              if (document.getElementById("menuListCategoryName" + cat_id)) {
                document.getElementById(
                  "menuListCategoryName" + cat_id
                ).style.position = "sticky";
              }
              setSelectedCategory(x);
            } else {
              if (document.getElementById("menuListCategoryName" + x._id)) {
                document.getElementById(
                  "menuListCategoryName" + x._id
                ).style.position = "relative";
              }
            }
          });
        }
      }
    }
  };

  const onCategoryClick = (cat) => {
    setSelectedCategory(cat);
    if (menuScrollRef.current) {
      if (menuScrollRef.current?.[cat?._id]?.current) {
        let el = document.getElementById("mainViewId");
        el.scrollTo(
          0,
          menuScrollRef.current?.[cat?._id]?.current?.offsetTop + 15
        );
        if (document.getElementById("menuListCategoryName" + cat?._id)) {
          document.getElementById(
            "menuListCategoryName" + cat?._id
          ).style.position = "sticky";
        }
      } else {
        menuScrollRef.current = {
          ...menuScrollRef.current,
          [cat?._id]: document.getElementById("menuListCategory" + cat?._id)
            ? {
                current: document.getElementById("menuListCategory" + cat?._id),
              }
            : React.createRef(),
        };
      }
    }
  };

  const onAddClick = (item) => {
    setMenuModelData(item);
    setAddMenuToCartModalVisible(true);
  };

  const onMenuArViewClick = (data) => {
    props.onMenuArViewClick(data);
  };

  const ARMenuLogo = ({ item }) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          position: "absolute",
          top: 0,
          right: 0,
          px: "6px",
          py: "3px",
          borderBottomLeftRadius: 6,
        }}
      >
        <ARMenuImg width={30} height={30} />
      </Box>
    );
  };

  const TabScrollBtn = forwardRef((props, ref) => {
    const { direction, disabled, ...other } = props;

    return (
      <ButtonBase component="div" ref={ref} {...other}>
        {direction === "right" ? (
          <Grid sx={{ cursor: "pointer" }}>
            {!disabled ? <RightArrow /> : <RightLightArrow />}
          </Grid>
        ) : (
          <Grid sx={{ cursor: "pointer" }}>
            {!disabled ? <LeftDarkArrow /> : <LeftArrow />}
          </Grid>
        )}
      </ButtonBase>
    );
  });

  return (
    <Grid item xs={12} className={`${styles["main-view"]}`}>
      <Grid item xs={12} className={`${styles["header-view"]}`}>
        <Grid
          item
          xs={12}
          sx={{ height: '52px' }}
          className={styles.topView}
        >
          {/*<MenuListHeader paddingHorizontal={PADDING_HORIZONTAL} />*/}
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"space-around"}
            px={`${PADDING_HORIZONTAL}px`}
            // sx={{ height: TOP_SEARCH_VIEW_HEIGHT }}
            sx={{
              height: '52px',
            }}
          >
            {showSearchInput ? (
              <MenuSearchInput
                value={searchText}
                setValue={setSearchText}
                placeholder={"Search Food Name"}
                border={`1px solid ${THEME_COLOR2}`}
                onClose={() => {
                  setSearchText("");
                  setShowSearchInput(false);
                }}
              />
            ) : (
              <>
                <Box display={"flex"} alignItems="center">
                  <Box ml={-1} sx={{ lineHeight: 1 }}>
                    <LeftDarkArrow
                      width={24}
                      height={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => props.onBackBtnClick()}
                    />
                  </Box>
                  <Box ml={0.5}>
                    <MenuTypeFilter
                      value={menuType}
                      list={menuTypeFilterList}
                      height={35}
                      setValue={setMenuType}
                    />
                  </Box>
                </Box>
                <Box className={`${styles["right-view"]}`}>
                  {isRestaurantClosedCtx ? (
                    <Typography
                      className={`${styles["rest-closed-text"]} ${styles["font-size"]}`}
                    >
                      RESTAURANT IS CLOSED
                    </Typography>
                  ) : null}
                  {/*<Box className={styles.dealView} mr={1}>*/}
                  {/*  <Typography className={styles.dealText} mr={0.6}>*/}
                  {/*    Deals*/}
                  {/*  </Typography>*/}
                  {/*  <DealIcon width={20} height={20} />*/}
                  {/*</Box>*/}
                  <Box
                    className={styles.searchView}
                    onClick={() => setShowSearchInput(true)}
                  >
                    <SearchIcon width={18} height={18} />
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className={styles.categoryList}
          // sx={{ height: CATEGORY_HEIGHT }}
          sx={{
            height: '62px',
            mt: 1,
          }}
        >
          <Tabs
            value={selectedCategory?._id}
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile={false}
            ScrollButtonComponent={(e) => <TabScrollBtn {...e} />}
            aria-label=""
            orientation="horizontal"
            sx={{
              height: '62px',
              pl: 0.5,
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {categoryList.map((item, index) => {
              let last_item = categoryList.length == index + 1;

              return (
                <Tab
                  key={index}
                  value={item._id}
                  onClick={() => onCategoryClick(item)}
                  label={item.name}
                  className={styles.categoryListText}
                  sx={{
                    fontFamily: "Inter !important",
                    fontWeight: '600 !important',
                    fontSize: '14px !important',
                    height: '62px',
                    mr: last_item ? 0 : 0.5,
                    // backgroundColor:
                    //   bgColor[item?._id == selectedCategory?._id],
                    // "&.MuiButtonBase-root": {
                    //   color: `${
                    //     textColor[item?._id == selectedCategory?._id]
                    //   } !important`,
                    // },
                    backgroundColor: item?._id === selectedCategory?._id ? '#02264E' : '#C3A376',
                    "&.MuiButtonBase-root": {
                      color: `${item?._id === selectedCategory?._id ? '#ffffff' : '#493D35'} !important`,
                    }
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${styles["menu-list-main-view"]}`}
        sx={{
          // top: `${TOP_VIEW_HEIGHT + CATEGORY_HEIGHT}px`,
          top: '124px'
        }}
      >
        {/*<Grid*/}
        {/*  container*/}
        {/*  item*/}
        {/*  xs={12}*/}
        {/*  py={2}*/}
        {/*  px={`${PADDING_HORIZONTAL}px`}*/}
        {/*  className={styles.filterList}*/}
        {/*>*/}
        {/*  {FILTER_LIST.map((item, index) => {*/}
        {/*    return (*/}
        {/*      <Box*/}
        {/*        px={2}*/}
        {/*        key={index}*/}
        {/*        className={styles.filterItemList}*/}
        {/*        onClick={() => setSelectedFilter(item)}*/}
        {/*      >*/}
        {/*        <Typography*/}
        {/*          className={styles.filterItemText}*/}
        {/*          sx={{*/}
        {/*            color: textColor2[item?._id == selectedFilter?._id],*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {item.name}*/}
        {/*        </Typography>*/}
        {/*      </Box>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Grid>*/}
        <Grid
          container
          item
          xs={12}
          pb={2}
          className={`${styles["content-view"]}`}
          alignItems={"flex-start"}
          id={"mainViewId"}
          sx={{
            height: `calc(100vh - ${
              TOP_VIEW_HEIGHT +
              CATEGORY_HEIGHT +
              (cartItems.length && addToCartExist ? BOTTOM_HEIGHT : 0)
            }px)`,
          }}
          onScroll={handleMainScroll}
        >
          {/*<Typography className={styles.menuMainTitle} pb={2}>*/}
          {/*  {selectedFilter?.name}*/}
          {/*</Typography>*/}
          {/*<Grid container item xs={12} pb={2} alignItems={"center"}>*/}
          {/*  <Box className={styles.menuSubTitleView}>*/}
          {/*    <Typography className={styles.menuSubTitle}>INDIAN</Typography>*/}
          {/*  </Box>*/}
          {/*  <UpArrowIcon width={15} height={15} style={{ cursor: "pointer" }} />*/}
          {/*</Grid>*/}
          {showNoItemText ? (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{
                height: `calc(100vh - ${
                  TOP_VIEW_HEIGHT +
                  CATEGORY_HEIGHT +
                  (cartItems.length && addToCartExist ? BOTTOM_HEIGHT : 0) +
                  30
                }px)`,
              }}
            >
              <Typography py={2} className={styles.noItems}>
                No Items
              </Typography>
            </Grid>
          ) : null}
          {categoryList.map((cat, catIndex) => {
            let last_category = catIndex == categoryList.length - 1;
            let min_height = 0;
            if (last_category) {
              min_height = `calc(100vh - ${
                TOP_VIEW_HEIGHT +
                CATEGORY_HEIGHT +
                (cartItems.length && addToCartExist ? BOTTOM_HEIGHT : 0) +
                10
              }px)`;
            }
            let menu_list = filterMenuByCat(
              menuList,
              cat,
              searchText,
              menuType
            );
            if (menu_list.length == 0) {
              return null;
            }
            return (
              <Grid
                key={catIndex}
                item
                xs={12}
                id={"menuListCategory" + cat?._id}
                ref={menuScrollRef.current?.[cat?._id]}
                cat-id={cat?._id}
                sx={last_category ? { minHeight: min_height } : null}
              >
                <Typography
                  pt={2}
                  pb={1}
                  px={`${PADDING_HORIZONTAL}px`}
                  className={styles.menuMainTitle}
                  id={"menuListCategoryName" + cat?._id}
                  sx={{
                    position: catIndex == 0 ? "sticky" : "relative",
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '24px !important',
                    textTransform: 'capitalize !important',
                  }}
                >
                  {cat?.name?.toLowerCase()}
                </Typography>
                {menu_list.length == 0 ? (
                  <Typography py={2} className={styles.noItems}>
                    No Items
                  </Typography>
                ) : (
                  menu_list.map((item, index) => {
                    const currency = CURRENCY_SYMBOL[item.price.currency];
                    const hideMenuThumbNail =
                      item?.extras?.hideMenuThumbNailImages;
                    const isModelExist =
                      item.modelId != null && item.modelId != "";
                    const itemPrice = (
                      item?.price?.value
                        ? item.price.value
                        : getRequiredModifierPrice(
                            item?.modifiers,
                            props.menuList
                          )
                    )?.toFixed(2);
                    const dishNotes =
                      item?.dishnote?.toLowerCase() == "nil" ||
                      item?.dishnote?.toUpperCase() == "NA" ||
                      item?.dishnote == "0"
                        ? []
                        : item?.dishnote?.split(",") ?? [];
                    let item_image = "";
                    if (item.images?.length) {
                      item_image = item.images?.[0];
                    }
                    const menuWidth =
                      !item_image && !addToCartExist && hideMenuThumbNail
                        ? 0
                        : hideMenuThumbNail && !addToCartExist
                        ? 40
                        : 120;
                    const menuHeight =
                      !item_image && hideMenuThumbNail
                        ? 0
                        : hideMenuThumbNail
                        ? 50
                        : 120;

                    const cartCount =
                      cartItems
                        .filter(
                          (x) =>
                            x._idCategory === item._idCategory &&
                            x._id === item._id
                        )
                        ?.reduce((sum, cur) => sum + cur.count, 0) ?? 0;

                    return (
                      <Grid
                        key={index}
                        className={styles.menuListItem}
                        item
                        xs={12}
                        px={`${PADDING_HORIZONTAL}px`}
                        onClick={() => onAddClick(item)}
                      >
                        <Box className={styles["menu-item-view"]}>
                          <Box>
                            <Typography
                              className={styles["menu-name-text"]}
                              style={{ color: THEME_COLOR2 }}
                            >
                              {item.name}
                            {Object.keys(MENU_TYPE2).includes(
                              item?.extras?.menuType
                            )
                              ? MENU_TYPE2?.[item?.extras?.menuType] ?? null
                              : null}
                            </Typography>

                          </Box>
                          {dishNotes.length ? (
                            <Box
                              className={`${styles["dish-notes-main-view"]}`}
                            >
                              {dishNotes.map((x, i) => {
                                return (
                                  <Box key={i} className={styles.dishNoteView}>
                                    <Typography className={styles.dishNoteText}>
                                      {x}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          ) : null}
                          <Typography
                            className={`${styles["menu-desc-text"]}`}
                            sx={{ color: THEME_COLOR2, py: 1.5 }}
                          >
                            {item.description}
                          </Typography>
                          <Typography
                            className={`${styles["menu-price-text"]}`}
                            sx={{ color: THEME_COLOR2 }}
                          >
                            {currency} {itemPrice}
                          </Typography>
                        </Box>
                        <Box className={styles.menuRightView} pl={1}>
                          {item_image || hideMenuThumbNail ? (
                            <Box
                              className={`${styles["menu-img-view"]} cursor-pointer`}
                              style={{
                                height: menuHeight,
                                width: menuWidth,
                              }}
                              onClick={() => {
                                onMenuArViewClick(item);
                              }}
                            >
                              {!hideMenuThumbNail ? (
                                <img
                                  className={styles.menuImgStyle}
                                  src={item_image}
                                  key={item_image}
                                  alt={item.name}
                                />
                              ) : null}
                              {isModelExist ? <ARMenuLogo item={item} /> : null}
                            </Box>
                          ) : (
                            <Box
                              className={`${styles["menu-img-icon-view"]} cursor-pointer`}
                              onClick={() => {
                                onMenuArViewClick(item);
                              }}
                            >
                              <MenuImg width={"60%"} height={"60%"} />
                              {isModelExist ? <ARMenuLogo item={item} /> : null}
                            </Box>
                          )}
                          {addToCartExist ? (
                            cartCount ? (
                              <Box
                                className={`${styles?.["menu-add-sub-btn"]}`}
                                sx={{
                                  mt: hideMenuThumbNail ? 0 : "-15px",
                                }}
                              >
                                <MenuListAddSubMenuBtn
                                  theme={"theme1"}
                                  count={cartCount}
                                  onAddSubMenu={() => onAddClick(item)}
                                />
                              </Box>
                            ) : (
                              <Box
                                className={styles.menuAddView}
                                sx={{
                                  backgroundColor: THEME_COLOR,
                                  mt: hideMenuThumbNail ? 0 : "-15px",
                                }}
                                onClick={() => onAddClick(item)}
                              >
                                <Typography className={styles.menuAddText}>
                                  ADD +
                                </Typography>
                              </Box>
                            )
                          ) : null}

                          {/*{index == 1 ? (*/}
                          {/*  <Typography*/}
                          {/*    mt={1}*/}
                          {/*    className={styles.text1}*/}
                          {/*    sx={{ cursor: "pointer" }}*/}
                          {/*  >*/}
                          {/*    Customizable*/}
                          {/*  </Typography>*/}
                          {/*) : null}*/}
                        </Box>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={styles.bottomView}
        sx={{
          height: cartItems.length && addToCartExist ? BOTTOM_HEIGHT : 0,
        }}
      >
        {/*<Typography className={styles.text1} mb={0.3}>*/}
        {/*  Government taxes as applicable, we levy a 7.5% service change*/}
        {/*</Typography>*/}
        {cartItems.length && addToCartExist ? (
          <Box
            className={styles.viewCart}
            sx={{
              backgroundColor: "#000000",
              height: BOTTOM_HEIGHT,
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={props.onCartClick}
          >
            <Typography className={styles.viewCartText}>View Cart</Typography>
            <Typography className={styles.viewCartCount} mt={0.3}>
              .&nbsp; &nbsp;{cartItems.length} Items
            </Typography>
          </Box>
        ) : null}
      </Grid>
      <AddMenuToCartModal
        page={"MenuList"}
        locale={"en"}
        visible={addMenuToCartModalVisible}
        data={menuModelData}
        addToCartExist={addToCartExist}
        menuList={props.menuList}
        onClose={() => {
          setAddMenuToCartModalVisible(false);
          setTimeout(() => {
            setMenuModelData(null);
          }, 200);
        }}
        onAddMenuItemToCart={onAddMenuItemToCart}
        // onAllergensClick={() => null}
      />
      <RestaurantOpenHoursModal open={isRestaurantClosedCtx} />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.restaurantData.cartItems,
    restaurantDetail: state.restaurantData.restaurantDetail,
    locale: state.restaurantData.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartItems: (data) => dispatch(updateCartItems(data)),
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updateSelectedMenuData: (data) => dispatch(updateSelectedMenuData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList2);

import React from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	FormControl,
	Button,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomTipModal({ open, onClose }) {
  const [value, setValue] = React.useState("0");

const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const formJson = Object.fromEntries(formData.entries());
  let tipAmount = formJson.tipAmount.trim() === "" ? 0 : parseInt(formJson.tipAmount, 10);

  if (isNaN(tipAmount) || tipAmount <= 0) {
    tipAmount = 0;
  }
  
  onClose(tipAmount);
};


	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={() => onClose(0)}
				fullWidth
				maxWidth='xs'
				PaperProps={{
					component: "form",
					onAbort: (event) => {
						event.preventDefault();
						onClose(0);
					},
					onSubmit: handleSubmit,
					sx: {
						borderRadius: "10px !important",
					},
				}}
			>
				<DialogTitle
					sx={{
						fontFamily: "Inter !important",
						fontSize: "18px !important",
						fontWeight: "600 !important",
						color: "#000 !important",
					}}
				>
					Tip Amount
					<IconButton
						aria-label='close'
						onClick={() => onClose(0)}
						sx={{
							position: "absolute",
							right: "8px",
							top: "8px",
							background: "none !important",
						}}
					>
						<CloseIcon
							sx={{
								color: "rgba(0, 0, 0, 0.4)",
								fontSize: "24px",
								"&:hover": {
									color: "rgba(0, 0, 0, 0.87)",
								},
							}}
						/>
					</IconButton>
				</DialogTitle>

				<DialogContent sx={{
          px: 3,
        }}>
					<FormControl fullWidth sx={{ mt: 1 }}>
						<InputLabel htmlFor='outlined-adornment-amount'>Amount</InputLabel>
						<OutlinedInput
							name='tipAmount'
							id='tipAmount'
							startAdornment={
								<InputAdornment position='start'>&pound;</InputAdornment>
							}
              endAdornment={<InputAdornment position="end">.00</InputAdornment>}
							label='Amount'
							autoFocus
							required
							margin='dense'
              onChange={(e) => setValue(e.target.value.replace(/\D/g, ""))}
							inputProps={{
									min: 1,
                  pattern: "\\d*",
							}}
              value={value}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions
					sx={{
						px: 3,
						pb: 3,
            display: "flex",
            justifyContent: "space-between",
					}}
				>
					<Typography variant='body2' sx={{
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 300
          }}>
						Grateful for your gratuity—thank you!
					</Typography>
					<Button
						onClick={onClose}
						type='submit'
						sx={{
							fontFamily: "Inter",
							fontWeight: 600,
							fontSize: "14px",
							background: "#BD9968 !important",
							color: "#ffffff !important",
							height: "40px",
							width: "89px",
							borderRadius: 0,
						}}
					>
						Add Tip
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

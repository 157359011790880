import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import CheckoutHeader from "../eazy-peasy-checkout/components/shared/CheckoutHeader";
import { formatCurrency } from "../../utils/utils";
import { useOrders } from "./context/OrdersContext";
import OrderStatus from "./components/OrderStatus";
import { formatDate } from "../../utils/utils";

const PastOrdersPage = () => {
	const navigate = useNavigate();

	const pastOrders = JSON.parse(localStorage.getItem("pastOrders"));
	const { restaurantIdCtx, tableNumberCtx } = useOrders();

	const handleBackButtonClick = () => {
		navigate(`/cart/${restaurantIdCtx}/table/${tableNumberCtx}`);
	};

	const handleNavigateToPastOrderDetails = (pastOrderId) => {
		navigate(`/restaurant/past-orders/order/${pastOrderId}`, {
			state: {
				order: pastOrders.find((item) => item._id === pastOrderId),
			},
		});
	};

	return (
		<>
			<CheckoutHeader
				headerTitle='Your Orders'
				onBackButtonClick={handleBackButtonClick}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					height: "100vh",
					boxSizing: "border-box",
					maxHeight: "100vh",
					overflowY: "auto",
					backgroundColor: "#FAFBFF",
				}}
			>
				<Box sx={{ p: 2 }}>
					{/* <Typography
						variant='h5'
						sx={{
							mt: 1,
							mb: 2,
							fontFamily: "Inter",
							fontWeight: 400,
							fontSize: 20,
						}}
					>
						Previous Orders
					</Typography> */}

					{pastOrders?.map((order) => {
						if (order?.cart.length === 0) {
							return null;
						}
						return (
							<Box
								key={order._id}
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "stretch",
									padding: 2,
									backgroundColor: "#FFFFFF",
									mb: 2,
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
									borderRadius: "5px",
								}}
								onClick={() => handleNavigateToPastOrderDetails(order._id)}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										width: "100%",
										alignItems: "center",
									}}
								>
									<Typography
										variant='h6'
										sx={{
											fontWeight: 500,
											fontFamily: "Inter",
											fontSize: 14,
											lineHeight: 1.4
										}}
									>
										Order: #{order?._id?.toUpperCase().substr(-5)}
									</Typography>

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Typography
											variant='h6'
											sx={{
												fontFamily: "Inter",
												fontWeight: 600,
												fontSize: 16,
												lineHeight: 1.4
											}}
										>
											{formatCurrency(order?.totalAmount)}
										</Typography>
									</Box>
								</Box>

								<Typography
									sx={{
										fontFamily: "Inter",
										fontWeight: 500,
										fontSize: 14,
										lineHeight: 1.4,
										py: 1.5
									}}
								>
									{order?.cart.length}{" "}
									{order?.cart.length === 1 ? "item" : "items"}
								</Typography>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography
										sx={{
											fontFamily: "Inter",
											fontWeight: 500,
											fontSize: 14,
										}}
									>
										Placed at {formatDate(order?.createdAt)}
									</Typography>

									<OrderStatus status={order?.transactionStatus} />
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</>
	);
};

export default PastOrdersPage;

import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { usePayment } from "../../context/PaymentContext";
import { clearCartData } from "../../../../redux/actions/restaurantDataActions";
import { useEffect, useState } from "react";
import CheckoutHeader from "../shared/CheckoutHeader";
import MiniInvoiceWidget from "../MiniInvoiceWidget";
import YourOrderWidget from "../YourOrderWidget";
import { formatCurrency } from "../../../../utils/utils";

const OrderSuccessPage = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { paymentSuccessful, setPaymentSuccessful } = usePayment();
	const [orderUpdationError, setOrderUpdationError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const cartItems = location.state.cartItems;

	const orderDetails = location.state.orderData;

	const restaurantId = orderDetails?._idRestaurant;
	const tableNumber = orderDetails?.tableNumber;

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_APP_API_URL}stripe/${location.state.orderId}/refresh-payment`,
					{
						method: "GET",
						headers: { "Content-Type": "application/json" },
					}
				);

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const data = await response.json();

				if (data.data.transactionStatus === "PAID") {
					const existingOrders =
						JSON.parse(localStorage.getItem("pastOrders")) || [];

					const orderExists = existingOrders.some(
						(order) => order._id === data.data._id
					);

					if (!orderExists) {
						const newOrder = {
							...data.data,
							tableNumber: tableNumber,
							cart: cartItems,
						};

						const updatedOrders = [...existingOrders, newOrder];

						localStorage.setItem("pastOrders", JSON.stringify(updatedOrders));
					}
				}

				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setOrderUpdationError(error.message);
				setIsLoading(false);
			}
		}

		fetchData();
	}, [location.state.orderId, tableNumber]);

	const handleBackToRestaurantButtonClick = async () => {
		setPaymentSuccessful(false);
		dispatch(clearCartData());
		if (location.state.tableNumber) {
			navigate(
				`/restaurant_menu/${restaurantId}/table/${location.state.tableNumber}`
			);
		} else {
			navigate(`/restaurant_menu/${restaurantId}`);
		}
	};

	if (!paymentSuccessful) {
		return <Navigate to={`/restaurant_menu/${restaurantId}`} replace />;
	}

	if (isLoading) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100vh'
			>
				<CircularProgress />
				<Typography ml={2}>Creating your order...</Typography>
			</Box>
		);
	}

	if (orderUpdationError) {
		return (
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				height='100vh'
			>
				<Typography color='error' variant='h6'>
					Error processing your order
				</Typography>
				<Typography>{orderUpdationError}</Typography>
				<Button
					variant='contained'
					color='primary'
					onClick={() => window.location.reload()}
				>
					Retry
				</Button>
			</Box>
		);
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='stretch'
			minHeight='100vh'
			overflow='auto'
			boxSizing='border-box'
			backgroundColor='#ffffff'
			pb={10}
		>
			<CheckoutHeader headerTitle='Order Overview' disableBackButton />

			<MiniInvoiceWidget orderData={location.state.orderData} />

			<YourOrderWidget
				orderData={location.state.orderData}
				cartItems={cartItems}
			/>

			<Box
				p={2}
				m={1}
				sx={{
					mx: 1,
					mt: 3,
					p: 2,
					boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
					borderRadius: "15px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						mb: 3,
					}}
				>
					<Typography sx={styles.thankyouText}>Your Bill</Typography>

					<Box className={styles.listView}>
						<Typography
							className={styles.totalVal}
							sx={{ fontFamily: "GilroyBold", fontSize: 20, color: "#212529" }}
						>
							{formatCurrency(orderDetails?.totalAmount)}
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "stretch",
					}}
				>
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							mb: 0.5,
						}}
					>
						<span
							style={{
								fontFamily: "GilroyRegular",
								fontSize: 13,
								color: "#212529",
							}}
						>
							Subtotal
						</span>
						<strong
							style={{
								fontFamily: "GilroyRegular",
								fontSize: 15,
								color: "#212529",
							}}
						>
							{formatCurrency(
								orderDetails?.totalAmount -
									(orderDetails?.tip ?? 0) -
									(orderDetails?.serviceCharge ?? 0)
							)}
						</strong>
					</Typography>

					{orderDetails?.serviceCharge ? (
						<Typography
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								mb: 0.5,
							}}
						>
							<span
								style={{
									fontFamily: "GilroyRegular",
									fontSize: 13,
									color: "#212529",
								}}
							>
								Service Fee
							</span>
							<strong
								style={{
									fontFamily: "GilroyRegular",
									fontSize: 15,
									color: "#212529",
								}}
							>
								{formatCurrency(orderDetails?.serviceCharge)}
							</strong>
						</Typography>
					) : null}

					{orderDetails?.tip ? (
						<Typography
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								mb: 0.5,
							}}
						>
							<span
								style={{
									fontFamily: "GilroyRegular",
									fontSize: 13,
									color: "#212529",
								}}
							>
								Tips
							</span>
							<strong
								style={{
									fontFamily: "GilroyRegular",
									fontSize: 15,
									color: "#212529",
								}}
							>
								{formatCurrency(orderDetails?.tip)}
							</strong>
						</Typography>
					) : null}
				</Box>
			</Box>

			<Button
				variant='contained'
				color='primary'
				onClick={handleBackToRestaurantButtonClick}
				sx={{
					position: "fixed",
					bottom: "20px",
					backgroundColor: "#212121",
					cursor: "pointer",
					textTransform: "capitalize",
					color: "#FFF",
					fontFamily: "Inter",
					fontSize: 14,
					fontWeight: 600,
					letterSpacing: "1px",
					borderRadius: "10px",
					height: 54,
					width: "calc(100% - 16px)",
					mx: 1,
					"&:hover": {
						backgroundColor: "#212121 !important",
					},
				}}
			>
				Back to Home
			</Button>
		</Box>
	);
};

export default OrderSuccessPage;

const styles = {
	dataView: {
		backgroundColor: "#FFF",
		borderRadius: "15px",
	},
	topView: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	thankyouText: {
		color: "#212529",
		fontSize: 23,
		fontFamily: "GilroyBold",
		textAlign: "center",
	},
	orderPlacedText: {
		color: "#212529",
		fontSize: 16,
		fontFamily: "GilroyMedium",
		textAlign: "center",
	},
	sendEmailText: {
		color: "#212529",
		fontSize: 14,
		fontFamily: "GilroySemiBold",
		textAlign: "center",
	},
	timeText: {
		color: "#212529",
		fontSize: 14,
		fontFamily: "GilroySemiBold",
		textAlign: "center",
	},
	addressTitle: {
		color: "#212529",
		fontSize: 15,
		fontFamily: "GilroyBlack",
	},
	titleText: {
		color: "#212529",
		fontSize: 15,
		fontFamily: "GilroySemiBold",
	},
	dataPickupView: {
		backgroundColor: "#FFF",
		position: "fixed",
		top: 0,
		left: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "center",
		height: "100%",
		width: "100%",
		overflow: "hidden",
	},
	descText: {
		color: "#212529",
		fontSize: 50,
		lineHeight: "50px",
		fontFamily: "GilroyBold",
		textAlign: "center",
	},
	lottieView: {
		height: "40%",
		display: "flex",
		justifyContent: "center",
	},
};

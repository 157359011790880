import React from "react";
import { Box, Typography } from "@mui/material";

import { formatCurrency } from "../../../utils/utils";

import styles from "./CartItemsWithModifiers.module.scss";

const CartItemsWithModifiers = ({ cartList }) => {
	return (
		<Box>
			<Box className={styles.cartItemListView} mt={2} mb={1}>
				{cartList?.map((item, i) => {
					return (
						<Box sx={{ mb: 1 }}>
							<Box key={i} className={styles.cartItemMainView}>
								<Box dir={"ltr"} display={"flex"} flexDirection={"row"}>
									<Box className={styles.itemQtyView}>
										<Typography className={styles.itemQtyText}>
											{item.count}x
										</Typography>
									</Box>
									<Box className={styles.itemNameView} pr={2}>
										<Typography className={styles.itemNameText}>
											{item.name}
										</Typography>
									</Box>
								</Box>
								<Box className={styles.itemPriceView}>
									<Typography className={styles.itemPriceText}>
										{formatCurrency(item?.price?.value * item?.count)}
									</Typography>
								</Box>
							</Box>
							{item?.selectedModifiers?.length > 0 &&
								item?.selectedModifiers?.map((modifier, modIndex) => (
									<Box key={modIndex} className={styles.modifierItemView}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Box className={styles.itemQtyView}>
												<Typography
													className={styles.itemQtyText}
													sx={{
														fontSize: "12px !important",
														fontFamily: "Inter !important",
														fontWeight: 300,
													}}
												>
													{item.count}x
												</Typography>
											</Box>
											<Box
												sx={{
													flex: 1,
												}}
												pr={2}
											>
												<Typography
													className={styles.modifierNameText}
													sx={{
														fontSize: "12px !important",
														fontFamily: "Inter !important",
														fontWeight: 300,
													}}
												>
													{modifier.name}
												</Typography>
											</Box>
											<Box className={styles.modifierPriceView}>
												<Typography
													className={styles.modifierPriceText}
													sx={{
														fontSize: "12px !important",
														fontFamily: "Inter !important",
														fontWeight: 300,
													}}
												>
													{modifier?.price?.value === 0
														? "FREE"
														: formatCurrency(
																modifier?.price?.value * item?.count
														  )}
												</Typography>
											</Box>
										</Box>
									</Box>
								))}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default CartItemsWithModifiers;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { MENU_HEADER_HEIGHT } from "../components/MenuListHeader";
import MenuCartListItem from "../components/MenuCartListItem";

import styles from "./Cart.module.css";

import { useOrders } from "../modules/past-orders/context/OrdersContext";

let PADDING_HORIZONTAL = 16;
let BTN_HEIGHT = 60;
let PRICE_HEIGHT = 65 + 26;
const CONTENT_HEIGHT =
	window.innerHeight - MENU_HEADER_HEIGHT - BTN_HEIGHT - 26;

const Cart = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	let { cartItemList, totalPrice, menuList, tableNumber, restaurantId } = props;
	const { setTableNumberCtx, setRestaurantIdCtx, setSubTotalCtx } = useOrders();
	const [cartList, setCartList] = useState([]);
	const confirmedOrders = JSON.parse(localStorage.getItem("pastOrders"));
	const showPastOrdersButton = confirmedOrders || confirmedOrders?.length > 0;

	useEffect(() => {
		let list = [];
		menuList.forEach((order) => {
			list.push({
				status: "confirmed",
				items: order?.cartItems ?? [],
				total: order?.totalPrice ?? 0,
			});
		});

		list.push({
			status: "pending",
			items: cartItemList,
			total: totalPrice,
		});
		setCartList(list);
	}, [cartItemList, menuList, totalPrice]);

	const onCheckout = () => {
		props.onCheckout();
	};

	const handleViewPastOrders = () => {
		setTableNumberCtx(tableNumber);
		setRestaurantIdCtx(restaurantId);
		setSubTotalCtx(totalPrice);
		navigate(`/restaurant/past-orders`);
	};

	let item_index = 0;

	const renderViewPastOrdersButton = () => {
		if (!showPastOrdersButton) {
			return null;
		}
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "none",
					padding: 0,
					height: PRICE_HEIGHT,
				}}
			>
				<Button
					variant='outlined'
					sx={{
						color: "#000000",
						borderColor: "#000000",
						fontSize: "14px",
						borderRadius: "10px",
						height: "45px",
						width: "327px",
						mb: "26px",
						boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
						textTransform: "capitalize",
						"&:hover, &:focus, &:active": {
							opacity: 1,
							backgroundColor: "#000000",
							borderColor: "#000000",
							color: "#FFF",
						},
					}}
					onClick={handleViewPastOrders}
				>
					View Orders
				</Button>
			</Box>
		);
	};
	return (
		<Grid
			item
			xs={12}
			sx={{
				minHeight:
					window.innerHeight -
					BTN_HEIGHT -
					(showPastOrdersButton ? PRICE_HEIGHT : 0),
				background: "#ffffff",
			}}
		>
			<Grid
				item
				xs={12}
				px={`${PADDING_HORIZONTAL}px`}
				sx={{
					height: CONTENT_HEIGHT - (showPastOrdersButton ? PRICE_HEIGHT : 0), // (totalPrice > 0 ? PRICE_HEIGHT : 0),
				}}
				className={styles.contentView}
				pt={1.5}
			>
				{cartList.map((item, index) => {
					if (item?.items.length === 0) {
						return null;
					}
					item_index = item_index + 1;
					return (
						<MenuCartListItem
							key={index}
							item={item}
							itemIndex={item_index}
							currency={props.currency}
							onAddSubMenuItemToCart={props.onAddSubMenuItemToCart}
							showOrder={true}
						/>
					);
				})}
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					height: BTN_HEIGHT + PRICE_HEIGHT + 26, // + (totalPrice > 0 ? PRICE_HEIGHT : 0),
					backgroundColor: "#FFFFFF",
				}}
				className={styles.bottomView}
			>
				{location.pathname.includes("/table") && renderViewPastOrdersButton()}

				<Container
					xs={12}
					sx={{
						height: BTN_HEIGHT,
						padding: "0 16px",
						display: "flex",
						gap: "6px",
					}}
				>
					<Box
						className={styles.btnView}
						sx={{
							backgroundColor: "#ffffff",
							border: "1px solid #000000",
							color: "#000000",
						}}
						onClick={props.onRestMenuNavigate}
					>
						<Typography className={styles.btnText}>Back</Typography>
					</Box>

					<Box
						className={styles.btnView}
						sx={{
							backgroundColor: "#000000",
							color: "#ffffff",
							...(cartItemList.length
								? {
										opacity: 1,
										cursor: "pointer",
								  }
								: {
										opacity: 0.5,
										cursor: "unset",
								  }),
						}}
						onClick={cartItemList.length ? onCheckout : null}
					>
						<Typography className={styles.btnText}>
							{props.onlineTablePayment ? "Checkout" : "Place Order"}
						</Typography>
					</Box>
				</Container>
			</Grid>
		</Grid>
	);
};

export default Cart;

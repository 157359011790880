import React from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Checkbox } from "@mui/material";
import { formatCurrency } from "../utils/utils";
import { ReactComponent as RadioOffIcon } from "../assets/images/radio_off2.svg";
import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on2.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import { ReactComponent as VegetarianFoodIcon } from "../assets/images/veg_icon.svg";

const ImageContainer = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 220px;
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-position: center;
	z-index: 1;
`;

const ContentContainer = styled(Box)`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	height: 100%;
	overflow-y: auto;
	z-index: 2;
	box-sizing: border-box;
`;

const Item = styled(Box)`
	margin-top: ${(props) => (props.noMenuImage ? "20px" : "16px")};
	margin-bottom: 16px;
	background-color: #ffffff;
	border-radius: 8px;
	display: flex;
	// position: sticky;
	// top: 16px;
	z-index: 4;
	box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
`;

const AddItemToCartInterface = ({
	itemData,
	menuList,
	onModifierClick,
	addedModifiers,
	onClose,
}) => {
	const isVegFood = itemData?.extras?.menuType?.toLowerCase() === "vegetarian";

	return (
		<Box sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
			<ImageContainer image={itemData?.images?.[0]} />

			<Box
				sx={{
					backgroundColor: "rgba(0, 0, 0, 0.5)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "50%",
					cursor: "pointer",
					width: "35px",
					height: "35px",
					position: "absolute",
					top: "16px",
					right: "16px",
					zIndex: 3,
				}}
				onClick={onClose}
			>
				<CloseIcon height={20} width={20} />
			</Box>

			<ContentContainer
				sx={{
					pb: "100px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						mt: itemData?.images.length ? "200px" : "60px",
						backgroundColor: "#FFFFFF",
						borderRadius: "8px",
						padding: "16px",
						zIndex: 3,
						boxShadow: "0 -4px 4px rgba(0, 0, 0, 0.1)",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "8px",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Inter !important",
								fontWeight: 600,
								fontSize: 18,
								color: "#011C3A",
								textTransform: "capitalize",
								display: "inline-flex",
								alignItems: "center",
								justifyContent: "flex-start",
								gap: 1,
							}}
						>
							{itemData?.name}

							{isVegFood && <VegetarianFoodIcon width={16} height={16} />}
						</Typography>

						<Typography
							sx={{
								fontFamily: "Inter !important",
								fontWeight: "600 !important",
								fontSize: "18px !important",
							}}
						>
							{formatCurrency(itemData?.price?.value)}
						</Typography>
					</Box>

					{itemData?.description ? (
						<Typography
							sx={{
								mt: 1.5,
								fontFamily: "Inter !important",
								fontWeight: "400 !important",
								fontSize: "16px !important",
								color: "#011C3A",
							}}
						>
							{itemData?.description}
						</Typography>
					) : null}
				</Box>

				{(itemData?.modifiers ?? []).map((item, i) => (
					<Item
						key={i}
						sx={{
							flexDirection: "column",
						}}
						noMenuImage={itemData?.images.length === 0}
					>
						<Box
							sx={{
								display: "flex",
								borderBottom: "1px solid #cccccc",
								alignItems: "center",
								padding: "16px",
								width: "100%",
								boxSizing: "border-box",
							}}
						>
							<Box
								sx={{
									flex: 1,
								}}
							>
								<Typography
									sx={{
										fontFamily: "Inter !important",
										fontSize: "16px !important",
										fontWeight: "600 !important",
									}}
								>
									{item.header}
								</Typography>
								<Typography
									sx={{
										fontFamily: "Inter !important",
										fontSize: "14px !important",
										fontWeight: "500 !important",
									}}
								>
									{item?.multiSelection
										? "Select any options"
										: "Select any 1 option"}
								</Typography>
							</Box>
							{item.required ? (
								<Box
									ml={1}
									px={1}
									py={0.5}
									sx={{
										borderRadius: "5px",
									}}
								>
									<Typography
										sx={{
											color: "#ffffff",
											fontFamily: "GilroyMedium !important",
											fontSize: "14px !important",
											textTransform: "uppercase !important",
										}}
									>
										required
									</Typography>
								</Box>
							) : null}
						</Box>

						<Box
							sx={{
								p: 2,
							}}
						>
							{item.items.map((x, i2) => {
								let menu_data = null;
								let menu_name = "";
								let menu_price = "";
								let find_index = menuList.findIndex((y) => y?._id === x);
								if (find_index >= 0) {
									menu_data = menuList?.[find_index];
									menu_name = menu_data?.name;
									menu_price = menu_data?.price?.value;
								}
								if(!menu_data) {
								  return null;
                }
								return (
									<Box
										key={"i" + i2}
										sx={{
											display: "flex",
											alignItems: "center",
											cursor: "pointer",
											pt: 1.5,
										}}
										onClick={() => onModifierClick(item, menu_data)}
									>
										<Box
											sx={{
												flex: 1,
											}}
										>
											<Typography
												sx={{
													fontFamily: "Inter !important",
													fontSize: "14px !important",
													textTransform: "capitalize",
													fontWeight: "600 !important",
													color: "#011C3A !important",
												}}
											>
												{menu_name}
											</Typography>
										</Box>

										{menu_price ? (
											<Typography
												sx={{
													mr: 1,
													fontFamily: "Inter !important",
													fontSize: "14px !important",
													fontWeight: "500 !important",
												}}
											>
												{formatCurrency(menu_price || 0)}
											</Typography>
										) : (
											<Typography
												sx={{
													fontFamily: "Inter !important",
													fontSize: "14px !important",
													fontWeight: "500 !important",
													mr: 1,
												}}
											>
												FREE
											</Typography>
										)}

										<Box>
											{item.multiSelection ? (
												<Checkbox
													checked={addedModifiers?.some(
														(y) =>
															y._idMenuItem === x && y._idModifier === item._id
													)}
													sx={{
														color: "#000",
														padding: 0,
														"& .MuiSvgIcon-root": { fontSize: 20 },
														"&.Mui-checked": {
															color: "#000",
														},
													}}
												/>
											) : addedModifiers?.some(
													(y) =>
														y._idMenuItem === x && y._idModifier === item._id
											  ) ? (
												<RadioOnIcon width={20} height={20} />
											) : (
												<RadioOffIcon width={20} height={20} />
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
					</Item>
				))}
			</ContentContainer>
		</Box>
	);
};

export default AddItemToCartInterface;
